export default [
  {
    header: 'menu.parametres.main',
    icon: 'PackageIcon',
    children: [
      {
        title: 'menu.parametres.services',
        route: 'apps-email',
        icon: 'MailIcon',
      },
      {
        title: 'menu.parametres.utilisateurs',
        route: 'apps-chat',
        icon: 'MessageSquareIcon',
      },
      {
        title: 'menu.parametres.prestations',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'menu.parametres.',
        route: 'apps-calendar',
        icon: 'CalendarIcon',
      },
      {
        title: 'menu.parametres.assurances',
        route: 'apps-email',
        icon: 'MailIcon',
      },
      {
        title: 'menu.parametres.residences',
        route: 'apps-chat',
        icon: 'MessageSquareIcon',
      },
      {
        title: 'menu.parametres.sous_localite',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'menu.parametres.configurations',
        route: 'apps-calendar',
        icon: 'CalendarIcon',
      },
    ],
  },
]
