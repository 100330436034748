export default [
  {
    header: 'menu.consultations.main',
    icon: 'EditIcon',
    children: [
      {
        title: 'menu.consultations.liste_attente',
        route: 'form-layout',
        icon: 'CopyIcon',
      },
      {
        title: 'menu.consultations.devis',
        route: 'form-wizard',
        icon: 'PackageIcon',
      },
      {
        title: 'menu.consultations.parametres.main',
        icon: 'CopyIcon',
        children: [
          {
            title: 'menu.consultations.parametres.motifs_consultations',
            route: 'forms-element-input',
          },
          {
            title: 'menu.consultations.parametres.formulaire',
            route: 'forms-element-input-group',
          },
          {
            title: 'menu.consultations.parametres.diagnostics',
            route: 'forms-element-input-mask',
          },
        ],
      },
    ],
  },
]
