export default [
  {
    header: 'menu.pharmacie.main',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'menu.pharmacie.produits',
        route: 'pages-account-setting',
        icon: 'SettingsIcon',
      },
      {
        title: 'menu.pharmacie.commandes',
        route: 'pages-profile',
        icon: 'UserIcon',
      },
      {
        title: 'menu.pharmacie.ventes_de_produits',
        route: 'pages-faq',
        icon: 'HelpCircleIcon',
      },
      {
        title: 'menu.pharmacie.regularisation_du_stock',
        route: 'pages-knowledge-base',
        icon: 'AlertCircleIcon',
      },
      {
        title: 'menu.pharmacie.inventaire_du_stock',
        route: 'menu.pharmacie.devis',
        icon: 'DollarSignIcon',
      },
      {
        title: 'menu.pharmacie.parametres.main',
        icon: 'LockIcon',
        children: [
          {
            title: 'menu.pharmacie.parametres.types_de_produit',
            route: 'auth-login-v1',
            target: '_blank',
          },
          {
            title: 'menu.pharmacie.parametres.formes_de_produit',
            route: 'auth-login-v2',
            target: '_blank',
          },
          {
            title: 'menu.pharmacie.parametres.classes_therapeutique',
            route: 'auth-register-v1',
            target: '_blank',
          },
          {
            title: 'menu.pharmacie.parametres.points_de_distribution',
            route: 'auth-register-v2',
            target: '_blank',
          },
          {
            title: 'menu.pharmacie.parametres.fournisseurs',
            route: 'auth-forgot-password-v1',
            target: '_blank',
          },
          {
            title: 'menu.pharmacie.parametres.motifs_ajustements',
            route: 'auth-forgot-password-v2',
            target: '_blank',
          },
        ],
      },
    ],
  },
]
