/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import dashboard from './dashboard'
import caisse from './caisse'
import pharmacie from './pharmacie'
import laboratoire from './laboratoire'
import dossiersPatients from './dossiers-patients'
import consultations from './consultations'
import imagerie from './imagerie'
import finance from './finance'
import parametres from './parametres'

// Array of sections
export default [
  ...dashboard,
  ...caisse,
  ...dossiersPatients,
  ...consultations,
  ...pharmacie,
  ...laboratoire,
  ...imagerie,
  ...finance,
  ...parametres,
]
