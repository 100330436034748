export default [
  {
    header: 'menu.laboratoire.main',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'menu.laboratoire.liste_attente',
        route: 'maps-leaflet',
        icon: 'MapIcon',
      },
      {
        title: 'menu.laboratoire.liste_examens',
        route: 'ui-feather',
        icon: 'EyeIcon',
      },
      {
        title: 'menu.laboratoire.devis',
        route: 'card-basic',
        icon: 'EyeIcon',
      },
      {
        title: 'menu.laboratoire.parametres.main',
        icon: 'PieChartIcon',
        children: [
          {
            title: 'menu.laboratoire.parametres.categories_analyse',
            route: 'charts-apex-chart',
          },
          {
            title: 'menu.laboratoire.parametres.analyses',
            route: 'charts-chartjs',
          },
          {
            title: 'menu.laboratoire.parametres.antibiotiques',
            route: 'charts-echart',
          },
          {
            title: 'menu.laboratoire.parametres.types_antibiotiques',
            route: 'components-aspect',
          },
          {
            title: 'menu.laboratoire.parametres.antibiogrammes',
            route: 'components-avatar',
          },
        ],
      },
    ],
  },
]
