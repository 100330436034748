export default [
  {
    header: 'menu.imagerie.main',
    icon: 'MoreHorizontalIcon',
    children: [
      {
        title: 'menu.imagerie.liste_attente',
        route: 'access-control',
        icon: 'ShieldIcon',
        // acl: {
        action: 'read',
        resource: 'ACL',
        // },
      },
      {
        title: 'menu.imagerie.liste_examens',
        route: null,
        icon: 'EyeOffIcon',
        disabled: true,
      },
      {
        title: 'menu.imagerie.devis',
        href: 'https://pixinvent.ticksy.com/',
        icon: 'LifeBuoyIcon',
      },
      // {
      //   title: 'menu.imagerie.',
      //   href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation',
      //   icon: 'FileTextIcon',
      // },
      {
        title: 'menu.imagerie.parametres.main',
        icon: 'MenuIcon',
        children: [
          {
            title: 'menu.imagerie.parametres.categories_actes',
            route: null,
          },
          {
            title: 'menu.imagerie.parametres.actes',
            route: null,
          },
          {
            title: 'menu.imagerie.parametres.modeles_resultat',
            route: null,
          },
        ],
      },
    ],
  },
]
