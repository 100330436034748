export default [
  {
    header: 'menu.finance.main',
    icon: 'PackageIcon',
    children: [
      {
        title: 'menu.finance.operations',
        route: 'apps-email',
        icon: 'MailIcon',
      },
      {
        title: 'menu.finance.devis',
        route: 'apps-chat',
        icon: 'MessageSquareIcon',
      },
      {
        title: 'menu.finance.parametres.main',
        icon: 'FileTextIcon',
        children: [
          {
            title: 'menu.finance.parametres.comptes',
            route: 'apps-invoice-list',
          },
          {
            title: 'menu.finance.parametres.sous_comptes',
            route: { name: 'apps-invoice-preview', params: { id: 4987 } },
          },
          {
            title: 'menu.finance.parametres.banques',
            route: { name: 'apps-invoice-edit', params: { id: 4987 } },
          },
          {
            title: 'menu.finance.parametres.planifications',
            route: { name: 'apps-invoice-add' },
          },
        ],
      },
      // {
      //   title: 'eCommerce',
      //   icon: 'ShoppingCartIcon',
      //   children: [
      //     {
      //       title: 'Shop',
      //       route: 'apps-e-commerce-shop',
      //     },
      //     {
      //       title: 'Details',
      //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
      //     },
      //     {
      //       title: 'Wishlist',
      //       route: 'apps-e-commerce-wishlist',
      //     },
      //     {
      //       title: 'Checkout',
      //       route: 'apps-e-commerce-checkout',
      //     },
      //   ],
      // },
      // {
      //   title: 'User',
      //   icon: 'UserIcon',
      //   children: [
      //     {
      //       title: 'List',
      //       route: 'apps-users-list',
      //     },
      //     {
      //       title: 'View',
      //       route: { name: 'apps-users-view', params: { id: 21 } },
      //     },
      //     {
      //       title: 'Edit',
      //       route: { name: 'apps-users-edit', params: { id: 21 } },
      //     },
      //   ],
      // },
    ],
  },
]
